import { jsx as _jsx } from "react/jsx-runtime";
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import React from 'react';
export default function RHFTextZone({ name, helperText, ...other }) {
    const { control } = useFormContext();
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => (_jsx(TextField, { ...field, fullWidth: true, multiline: true, value: typeof field.value === 'number' && field.value === 0 ? '' : field.value, error: !!error, helperText: error ? error?.message : helperText, ...other })) }));
}
